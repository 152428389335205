<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg8 mt-6>
        <v-card elevation="2">
          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
            <v-tab>Add School</v-tab>
            <v-tab>Add Hostel </v-tab>
            <v-tab>Add Hostel Block </v-tab>
            <!-------------------------TAB1--------------------->
            <v-tab-item>
              <v-layout wrap>
                <v-flex xs12 pa-6>
                  <v-card flat class="rounded-xl">
                    <v-layout wrap>
                      <v-flex
                        xs7
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 20px"
                      >
                        School List
                      </v-flex>
                      <v-flex
                        xs3
                        align-self-center
                        class="text-left nsregular"
                        pr-2
                      >
                        <v-text-field
                          v-model="keyword"
                          dense
                          class="rounded-xl"
                          label="Search"
                          outlined
                          hide-details
                          clearable
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-btn
                          width="100px"
                          dark
                          color="#766BC0"
                          class="py-4"
                          @click="scoladddialogue = true"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          <span
                            class="text-left nsregular"
                            style="font-weight: 400; font-size: 12px"
                            >Add</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout py-3>
                      <v-flex
                        xs1
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 18px"
                      >
                        <span>No.</span>
                      </v-flex>
                      <v-flex
                        xs9
                        class="text-left nsbold"
                        style="font-weight: 600; font-size: 18px"
                      >
                        <span>Name</span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      v-for="(item, i) in student"
                      :key="i"
                      py-2
                      :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
                    >
                      <v-flex xs1 class="text-left nsregular">
                        <span>{{ i + 1 }}</span>
                        <!-- <span v-if="page == 1">
                  {{ i + 1 }}
                </span>
                <span v-else>
                  {{ i + 1 + 20 * (page - 1) }}
                </span> -->
                      </v-flex>
                      <v-flex
                        xs9 v-if="item.SchoolName"
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 18px"
                      >
                        <span>{{ item.SchoolName }}</span>
                      </v-flex>
                      <v-flex xs1 v-if="item">
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="(curItem = item), (scoleditdialogue = true)"
                            >mdi-circle-edit-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs1>
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="
                              (scoldeletedialoge = true), (curId = item._id)
                            "
                            >mdi-delete-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <!-------------------------TAB2--------------------->
            <v-tab-item>
              <v-layout wrap>
                <v-flex xs12 pa-6>
                  <v-card flat class="rounded-xl">
                    <v-layout wrap>
                      <v-flex
                        xs7
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 20px"
                      >
                        Hostel List
                      </v-flex>
                      <v-flex
                        xs3
                        align-self-center
                        class="text-left nsregular"
                        pr-2
                      >
                        <v-text-field
                          v-model="keyword2"
                          dense
                          class="rounded-xl"
                          label="Search"
                          outlined
                          hide-details
                          clearable
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-btn
                          width="100px"
                          dark
                          color="#766BC0"
                          class="py-4"
                          @click="hosteladddialogue = true"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          <span
                            class="text-left nsregular"
                            style="font-weight: 400; font-size: 12px"
                            >Add</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout py-3>
                      <v-flex
                        xs1
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 18px"
                      >
                        <span>No.</span>
                      </v-flex>
                      <v-flex
                        xs9
                        class="text-left nsbold"
                        style="font-weight: 600; font-size: 18px"
                      >
                        <span>Name</span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      v-for="(item, i) in hostelList"
                      :key="i"
                      py-2
                      :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
                    >
                      <v-flex xs1 class="text-left nsregular">
                        <span>{{ i + 1 }}</span>
                        <!-- <span v-if="page == 1">
                  {{ i + 1 }}
                </span>
                <span v-else>
                  {{ i + 1 + 20 * (page - 1) }}
                </span> -->
                      </v-flex>
                      <v-flex
                        xs9 v-if="item.hostelName"
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 18px"
                      >
                        <span>{{ item.hostelName }}</span>
                      </v-flex>
                      <v-flex xs1 v-if="item">
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="
                              (hostelcurItem = item),
                                (hosteleditdialogue = true)
                            "
                            >mdi-circle-edit-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs1>
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="
                              (hosteldeletedialoge = true),
                                (hostelId = item._id)
                            "
                            >mdi-delete-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-tab-item>
            <!-------------------------TAB3--------------------->
            <v-tab-item>
              <v-layout wrap>
                <v-flex xs12 pa-6>
                  <v-card flat class="rounded-xl">
                    <v-layout wrap>
                      <v-flex
                        xs7
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 20px"
                      >
                        Hostel Block List
                      </v-flex>
                      <v-flex
                        xs3
                        align-self-center
                        class="text-left nsregular"
                        pr-2
                      >
                        <v-text-field
                          v-model="keyword2"
                          dense
                          class="rounded-xl"
                          label="Search"
                          outlined
                          hide-details
                          clearable
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-btn
                          width="100px"
                          dark
                          color="#766BC0"
                          class="py-4"
                          @click="hostelblockadddialogue = true"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                          <span
                            class="text-left nsregular"
                            style="font-weight: 400; font-size: 12px"
                            >Add Block</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout py-3>
                      <v-flex
                        xs1
                        class="text-left nsbold"
                        style="font-weight: 700; font-size: 18px"
                      >
                        <span>No.</span>
                      </v-flex>
                       <v-flex
                        xs2
                        class="text-left nsbold"
                        style="font-weight: 600; font-size: 18px"
                      >
                        <span>Block</span>
                      </v-flex>
                      <v-flex
                        xs4
                        class="text-left nsbold"
                        style="font-weight: 600; font-size: 18px"
                      >
                        <span>Hostel</span>
                      </v-flex>
                       <v-flex
                        xs3
                        class="text-left nsbold"
                        style="font-weight: 600; font-size: 18px"
                      >
                        <span>Warden</span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      v-for="(item, i) in hostelblockList"
                      :key="i"
                      py-2
                      :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
                    >
                      <v-flex xs1 class="text-left nsregular">
                        <span>{{ i + 1 }}</span>
                        <!-- <span v-if="page == 1">
                  {{ i + 1 }}
                </span>
                <span v-else>
                  {{ i + 1 + 20 * (page - 1) }}
                </span> -->
                      </v-flex>
                      <v-flex
                        xs2 v-if="item.Block"
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 18px"
                      >
                        <span>{{ item.Block }}</span>
                      </v-flex>
                      <v-flex
                        xs4 v-if="item.mainHostelId"
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 18px"
                      >
                        <span>{{ item.mainHostelId.hostelName }}</span>
                      </v-flex>
                      <v-flex
                        xs3 v-if="item.hostelWarden"
                        class="text-left nsregular"
                        style="font-weight: 400; font-size: 18px"
                      >
                        <span>{{ item.hostelWarden[0].name }}</span>
                      </v-flex>
                      <v-flex xs1 v-if="item">
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="
                              (hostelblockcurItem = item),
                                (hostelblockeditdialogue = true)
                            "
                            >mdi-circle-edit-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs1>
                        <span
                          ><v-icon
                            style="cursor: pointer"
                            @click="
                              (hostelblockdeletedialoge = true),
                                (hostelblockId = item._id)
                            "
                            >mdi-delete-outline</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog width="400px" v-model="scoldeletedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm Delete</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to delete this record?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="scoldeletedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="deleteCategory()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="hostelblockdeletedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm Delete</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to delete this record?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hostelblockdeletedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="deleteHostelBlock()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog width="400px" v-model="hosteldeletedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm Delete</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to delete this record?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hosteldeletedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="deleteHostel()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="scoleditdialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Edit School </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap v-if="curItem">
          <v-flex xs12 pa-4 v-if="curItem.SchoolName">
            <v-text-field
              v-model="curItem.SchoolName"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="scoleditdialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="editCategory()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="hosteleditdialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Edit Hostel </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap v-if="hostelcurItem">
          <v-flex xs12 pa-4 v-if="hostelcurItem.hostelName">
            <v-text-field
              v-model="hostelcurItem.hostelName"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hosteleditdialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="editHostel()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="hostelblockeditdialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Edit Hostel Block </v-toolbar-title>
        </v-toolbar>
 <v-layout wrap v-if="hostelblockcurItem">
          <v-flex xs12 pa-4>
            <v-text-field
              autofocus
              v-model="hostelblockcurItem.Block"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 pa-4 v-if="hostelblockcurItem.mainHostelId">
            <v-select 
              :items="hostelList"
              v-model="hostelblockcurItem.mainHostelId"
              label="Hostel"
              item-text="hostelName"
              item-value="_id"
              clearable
              outlined
              hide-details
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 pa-4 v-if="hostelblockcurItem.hostelWarden">
            <v-select
              :items="wardenList"
              v-model="hostelblockcurItem.hostelWarden[0]"
              label="Warden"
              item-text="name"
              item-value="_id"
              clearable
              outlined
              hide-details
              dense
            ></v-select>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hostelblockeditdialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="editHostelBlock()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="scoladddialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Add Item </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap>
          <v-flex xs12 pa-4>
            <v-text-field
              autofocus
              v-model="schoolName"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="scoladddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="addCategory()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="hosteladddialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Add Hostel </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap>
          <v-flex xs12 pa-4>
            <v-text-field
              autofocus
              v-model="hostel"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hosteladddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="addHostel()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="hostelblockadddialogue">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Add Hostel Block </v-toolbar-title>
        </v-toolbar>

        <v-layout wrap>
          <v-flex xs12 pa-4>
            <v-text-field
              autofocus
              v-model="hostelblock"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 pa-4>
            <v-select
              :items="hostelList"
              v-model="hostel"
              label="Hostel"
              item-text="hostelName"
              item-value="_id"
              clearable
              outlined
              hide-details
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 pa-4>
            <v-select
              :items="wardenList"
              v-model="warden"
              label="Warden"
              item-text="name"
              item-value="_id"
              clearable
              outlined
              hide-details
              dense
            ></v-select>
          </v-flex>
        </v-layout>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="hostelblockadddialogue = false"
            >Cancel</v-btn
          >
          <v-btn
            color="#766BC0"
            class="body-2 font-weight-bold"
            outlined
            @click="addHostelBlock()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [],
      hostelList: [],
      wardenList: [],
      hostelblockList:[],
      hostelblockcurItem:[],
      warden: null,
      schoolName: null,
      scoldeletedialoge: false,
      scoleditdialogue: false,
      scoladddialogue: false,
      hosteldeletedialoge: false,
      hostelblockdeletedialoge: false,
      hosteleditdialogue: false,
      hostelblockeditdialogue: false,
      hosteladddialogue: false,
      hostelblockadddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      hostelcurId: null,
      hostelId: null,
      hostelblockId: null,
      hostel: null,
      hostelblock: null,
      hostelcurItem: [],
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      page: 1,
      Pagelength: 0,
      keyword: null,
      test: null,
      keyword2: null,
    };
  },
  mounted() {
    this.getData();
    this.getHostel();
    this.getHostelBlock();
    this.getHostelWarden();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
      this.getHostel();
    },
    keyword() {
      this.getData();
    },
    keyword2() {
      this.getHostel();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/school/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getHostel() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword2,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hostelList = response.data.data;
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    }, 
    getHostelBlock() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/sub/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword2,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hostelblockList = response.data.data;
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getHostelWarden() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/hostel/warden/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword2,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.wardenList = response.data.data;
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/school/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.scoldeletedialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteHostel() {
      axios({
        url: "/main/hostel/name/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.hostelId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.hosteldeletedialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getHostel();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getHostel();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteHostelBlock() {
      axios({
        url: "/sub/hostel/name/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.hostelblockId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.hostelblockdeletedialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getHostelBlock();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getHostelBlock();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //add button
    addCategory() {
      axios({
        url: "/school/add",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          SchoolName: this.schoolName,
        },
      })
        .then((response) => {
          this.scoladddialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          this.name = null;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addHostel() {
      axios({
        url: "/main/hostel/name/add",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          hostelName: this.hostel,
        },
      })
        .then((response) => {
          this.hosteladddialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getHostel();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          this.hostel = null;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addHostelBlock() {
      axios({
        url: "/sub/hostel/name/add",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          Block : this.hostelblock,
          hostelWarden: this.warden,
          mainHostelId: this.hostel,
        },
      })
        .then((response) => {
          this.hostelblockadddialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getHostelBlock();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.warden =null;
          this.hostel = null;
          this.hostelblock = null;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //edit
    editCategory() {
      axios({
        url: "/school/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          SchoolName: this.curItem.SchoolName,
        },
      })
        .then((response) => {
          this.scoleditdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editHostel() {
      axios({
        url: "/main/hostel/name/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.hostelcurItem._id,
          hostelName: this.hostelcurItem.hostelName,
        },
      })
        .then((response) => {
          this.hosteleditdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getHostel();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editHostelBlock() {
      this.test = this.hostelblockcurItem.hostelWarden[0]
      axios({
        url: "/sub/hostel/name/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          Block : this.hostelblockcurItem.Block,
          hostelWarden: this.test,
          mainHostelId: this.hostelblockcurItem.mainHostelId,
          id: this.hostelblockcurItem._id
        },
      })
        .then((response) => {
          this.hostelblockeditdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getHostelBlock();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.hostelblockcurItem.hostelblock = null,
          this.hostelblockcurItem.hostel = null,
          this.hostelblockcurItem.warden = null,
          this.hostelblockcurItem._id = null
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>